/**
* Loader styles
**/
.deb-loader {
  &--spinner {
    top: 50%;
    margin-top: -20px;
    width: 100%;
    text-align: center;
    position: absolute;
  }
  &--text {
    color: $color-primary;
    margin: 3px 0;
    font-size: 14px;
  }
  &--item {
    &__circular {
      size: $loader-spinner-size;
      animation: loading-rotate 2s linear infinite;
      height: 50px;
      width: 50px;
    }
    &__path {
      animation: loading-dash 1.5s ease-in-out infinite;
      stroke-dasharray: 90, 150;
      stroke-dashoffset: 0;
      stroke-width: 2;
      stroke: $color-primary;
      stroke-linecap: round;
    }
  }
}

@keyframes loading-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}
