/**
* Global styles
**/
* {
    font-size: 12px;
}

html {
    overflow-y: visible !important;
    overflow-x: hidden !important;
}

body {
    overflow-y: auto !important;
    background-color: #F1F2F6;
}

#app {
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    position: fixed;
    //background-image: url(../../img/background-1.svg), url(../../img/background-2.svg);
    background-size: auto;
    background-repeat: no-repeat;
    background-position: right top, left bottom;
}

.deb-main-title {
    color: #324053;
    font-size: 1.08rem;
    font-family: $font-primary;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 20px;

    &--turnAttended {
        font-size: 1.2rem !important;
    }
}

.deb-text {
    color: #404041;
    font-size: 1rem;
    line-height: 1.1em;
    text-align: center;
    strong {
        //color: #863DFF;
    }
    &--center {
        text-align: center;
        margin-bottom: 1.5em;
    }
}

.deb-back-button {
    z-index: 2;

    i {
        color: #D0C9D6;
    }

    i:hover {
        color: #11c1f3;
    }
}

.content.has-header {
    top: 0;
    padding-top: $bar-height;
    padding-bottom: $bar-height / 2;
}

.deb-button {
    width: 100%;
    padding: 0.8em 1.5em;
    font-size: 1.6em;
    border-radius: 3px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.bar {
    .ion-home.button-clear {
        color: #11c1f3;

        &:before {
            font-size: 28px !important;
        }
    }
}

.img-responsive {
    max-width: 100%;
    height: auto;
}

.img-fixed-responsive {
    padding-top: 1em;
    width: 7em;
}

a {
    text-decoration: none;
}

.container-body {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    padding: 4em 0 12em;

    &__searchCode {
        padding: 4em 0 6em;
    }
}


.button-primary {
    background-color: $color-primary !important;
    font-family: $font-primary;
    transition: 0.3s;

    &[disabled] {
        pointer-events: auto;
    }


    &--fixed {
        position: fixed;
        z-index: 999;
        width: 85% !important;
        left: 0;
        right: 0;
        bottom: 4em;
        margin: auto;

        @media (max-width: 768px) {
            bottom: 2.5em;
            width: 100% !important;
            border-radius: 0px;
        }
    }

    &--bold {
        font-weight: 700;
    }
}

.info-window {
    border-radius: 2px !important;

    &__data-container {
        color: rgba(76, 76, 76, 1);
        padding-top: 0.17em;

        text-align: left;
        list-style-type: none;
    }

    &__data-list {}

    &__data-list-item {}

    &__closed-warn {
        color: $color-cancel;
        font-size: 1em;
        margin-bottom: 0.17em;
        font-weight: 400;
    }

    &__title {
        text-align: left;
        margin-bottom: 0.17em;
        font-size: 1.15em;
    }
}

.button-secondary {
    background-color: $color-secondary !important;
    font-family: $font-primary;
    transition: 0.3s;
    border-width: 0;

    &:hover {
        background-color: adjust_color($color-secondary, $lightness: -20%) !important;
    }

    &--fixed {
        position: fixed;
        z-index: 1000;
        width: 5% !important;
        min-width: 50px;
        left: 0;
        bottom: 4em;
        margin: auto;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        @media (max-width: 768px) {
            bottom: 2.5em;
            width: 50px !important;
            border-radius: 0;
        }

        @media (max-width: 718px) {
            background: linear-gradient(to right, $color-secondary 0%, $color-secondary 75%, adjust_color($color-secondary, $lightness: -5%) 100%);
        }

        &:hover {
            @media (max-width: 718px) {
                background: adjust_color($color-secondary, $lightness: -20%) !important;
            }
        }

        &:disabled {
            background: adjust_color($color-secondary, $saturation: -20%, $lightness: 10%) !important;
        }
    }
}

.container-button {
    margin: 2em;
}

button.button.button-calm.button-block.button-primary.deb-button-cancel.deb-button-cancel__smallMargin {
    background-color: #fff !important;
}

.deb-button-cancel {
    background-color: #fff !important;
    color: $color-cancel !important;
    transition: 0.3s;
    margin: 2em 0;
    border: 1px solid $color-cancel !important;

    &__smallMargin {
        margin: 1em 0;
    }

    &:hover {
        color: $color-cancel !important;
        background-color: $color-cancel !important;
    }

    &:active {
        color: #fff;
        background-color: $color-cancel !important;
    }

    &_fixhover:hover {
        color: #fff !important;
        background-color: $color-cancel !important;
    }
}

/**
* Views
**/
.viewLogin {
    background-color: #ffffff;

    &__title,
    &__text {
        margin-bottom: 0;
    }

    &__text {
        font-size: 1rem;
    }

    .scroll {
        height: 100%;
        @include align-flex(column, center, top, wrap);
    }

    .viewLogin__logoContainer {
        width: 100%;
        height: 35%;
        @include align-flex;

        //Elememnto logo
        .logoContainer__logo {
            max-width: 75%;
            height: auto;
        }
    }

    .viewLogin__formContainer {
        @include align-flex;
        width: 100%;
        height: 60%;

        /* Nuevos estilos para rediseño de la app */
        .list {
            width: 100%;
            padding: 5%;
            margin-top: 0;
        }
    }
}

.viewSignUp {
    background-color: #ffffff;

    &__text {
        margin-top: 1em;
        padding: 0 2em;
    }

    .scroll {
        height: 100%;
        @include align-flex(column, center, top, wrap);
    }

    &__logoContainer {
        width: 100%;
        height: 40%;
        //background-position: center center;
        //background: url(../../img/background.png) no-repeat;
        background-size: cover;
        @include align-flex;

        //Elememnto logo
        .logoContainer__logo {
            max-width: 100%;
            height: auto;
        }
    }

    &__formContainer {
        @include align-flex;
        width: 100%;
        height: 60%;

        .formContainer__form {
            width: 90%;
        }

        .list {
            width: 100%;
            padding: 5%;
            margin-top: 0;
        }
    }

    &__valid {
        input:invalid {
            border-bottom: 1px solid darkred;
            padding-right: 1em;
        }
    }
}

.viewSearchCode {
    .content {
        //background-image: url(../../img/background-1.svg), url(../../img/background-2.svg);
        background-size: auto;
        background-repeat: no-repeat;
        background-position: right top, left bottom;
        min-height: 80vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .search-container {
        margin-top: 2vh;
        text-align: center;
    }

    .list {
        margin: 1em 0;
    }

    .app-select {
        text-align: left;
        text-align-last: left;
    }
}

.viewProfile {
    &__form-container .viewProfile__secondary-button {
        display: flex;
        justify-content: center;
        color: #ababab;

        &:hover {
            color: #666666;
            text-decoration: underline;
        }
    }

    &__form-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__text {
        @media screen and (min-width:650px) {
            text-align: center;
        }

        padding: 0 0.8em;
    }

    &__form {
        margin-bottom: 1em;
    }

    &__form-container .viewProfile__input {
        display: flex;
        margin: 0;

        & input {
            width: 100%;
            margin: 0.3em 0;
            padding: 1.5em;
        }

        &:first-of-type {
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
        }

        &:last-of-type {
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
        }

        &::placeholder {
            color: #929497;
        }
    }
}

.viewSelectQueue,
.viewProfile,
.viewSelectBranch {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    & &__search-bar {
        display: inline-block;
        font-size: 0.8rem;
        height: 1.6rem;
        padding: 2px 5px 2px 5px;
        width: 100%;
        transition: 0.15s;
        border: 1px solid #eae9e9;
        border-radius: 3px;

        input {
            display: inline-block;
            vertical-align: top;
            margin-left: 4px;
            padding: 0;
            width: 90%;
            height: 100%;
            background-color: transparent;
        }
    }

    & &__search-bar:focus-within {
        border-color: #060a27;
    }

    &__imgContainer {
        display: flex;
        @include align-flex;
    }

    &__queueOrAppoitment {
        font-weight: 600;
        display: inline-block;
    }

    &__img {
        max-width: 55%;
    }

    &__maxImg {
        max-width: 185px !important;
    }

    &__title {
        margin: 0;
    }

    &__list {
        position: relative;
        margin-top: 1em;
        width: 100%;
        height: 80%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__closed {
        color: $color-cancel;
        margin: 0.5em 0 0.3em;
        text-align: right;
        font-size: 1.2em;
    }

    &__list .viewSelectBranch__sortByDistanceButton {
        font-size: 0.8rem;
        float: right;
        background-color: transparent;
        color: $color-primary;

        &:hover {
            color: adjust_color($color-primary, $lightness: -25);
        }
    }

    &__branch-title {
        border: 1px solid #eae9e9;
        border-radius: 4px;
        width: 100%;
        text-align: left;
        color: #404041;
        position: relative;
        z-index: 2;
        overflow: hidden;
        cursor: pointer;

        label {
            padding: 12px 15px;
        }
    }

    &__branch-distance {
        color: hsl(0, 0, 50);
        font-size: 0.9em;
        margin-left: 15px;
        margin-top: -15px;
        display: block;
        margin-bottom: 10px;
    }

    .virtual-attention.icon {
        font-size: 1.5em;
        margin-left: 4px;
        position: absolute;
        right: 52px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.info-right {
    &__button-expand {
        padding-top: 3px;
        border-top: 1px solid #cccccc;
        font-size: 1.15rem;
        min-width: 100px;

        & i {
            margin: 0 auto;
        }
    }
}

.recall-btn {
    width: 45%;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #060a27 !important;
    font-family: Open Sans, sans-serif;
    transition: .3s;
    padding: 0 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    vertical-align: top;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 42px;
    cursor: pointer;
    color: #fff;

    @media (max-width: 600px) {
        width: 85%;
    }
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    border-top: 1px solid #D0D5DD;
    margin-top: 2em;

    button {
        @media (max-width: 600px) {
            width: 100%;
        }

        width: 45%;
        margin: 3%;
        margin-top: 2em !important;
    }
}

.turnView {

    //background-image: url(../../img/background-1.svg), url(../../img/background-2.svg);
    //background-size: auto;
    //background-repeat: no-repeat;
    //background-position: right top, left bottom;
    .container-card-turn {
        display: flex;
        justify-content: center;
        min-height: 90vh;
        align-items: center;
        margin-bottom: 3em;
        padding-bottom: 20%;
    }

    .scroll {
        height: 100%;
    }

    .content {
        padding: 0;
        margin-top: 10px;

        &.finished {
            //background-image: url(../../img/background-1.svg), url(../../img/background-2.svg);
            background-size: auto;
            background-repeat: no-repeat;
            background-position: right top, left bottom;
            min-height: 100vh;
            display: flex;
            justify-content: center;
            text-align: center;
            flex-direction: column;
        }
    }

    &__turn-container {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__title {
        color: white;
        font-weight: 400;
        margin: 0;
    }

    &__main-card {
        min-width: 630px;
        padding-top: 0;
        margin: 0 2em;
    }

    &__turn-box {
        h2 {
            font-size: 1.5rem;
        }

        p {
            font-size: 3rem;
            color: $color-primary;
            font-weight: 700;
            padding: .4em;

            span {
                color: #5C5B5C;
                font-size: 35px;
            }
        }
    }

    &__waiting-videocall-title {
        font-size: 1rem;
        font-weight: 800;
        margin-bottom: 10px;
        display: block;
        text-align: center;
    }

    &__waiting-title {
        font-size: 1rem;
    }

    &__turn-info-box {
        margin-top: 1em;
    }

    &__info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 0.875rem;

        &-noborder {
            border: 0;
            span {
                text-wrap: wrap;
                text-align: end;
            }
        }

        &:last-child {
            border-bottom-width: 0;
        }

        .data {
            user-select: all;
            color: #863DFF;
            font-weight: 400;
            display: flex;
            align-items: center;

            .data__action {
                padding: 0.1875rem;
                height: 1.3rem;
                width: 1.3rem;
                border-radius: 0.675rem;
                margin-left: 5px;

                transition: 0.05s;
                background-color: #060a27;

                &:hover {
                    background-color: #94c4e7;
                }
            }
        }

        &__info {
            font-size: 0.875rem;

            .data {
                color: #863DFF;
                font-weight: 400;
                float: right;
            }
        }

        .turn-info-box {
            margin-bottom: 4em;
        }

        .finished {
            .row {
                padding-top: 1em;
                text-align: center;
            }
        }

        .only-turn .item.item-text-wrap {
            padding: 5em 1em;
        }

        .only-turn .card.turnView__main-card {
            margin: 1em;
        }
    }

    .only-turn .item.item-text-wrap {
        padding: 5em 1em;
    }

    .only-turn .card.turnView__main-card {
        margin: 1em;
    }

    &__buttons-container {
        background-color: white;
        font-size: 71%;
        padding: 0 1.4em 0 1.4em;
        display: flex;
        flex-direction: column-reverse;
    }

    &__viewport {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.videocallView {
    //background-image: url(../../img/background-1.svg), url(../../img/background-2.svg);
    background-size: auto;
    background-repeat: no-repeat;
    background-position: right top, left bottom;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .videocallContainer {
        width: 100vw;
        height: 100vh;
        align-self: center;

        h1 {
            font-size: 1.7rem;
        }

        .videocallIframe {
            width: 100%;
            height: 100%;
        }
    }
}



.offlineView,
.errorView {
    position: fixed;
    background: white;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    max-height: 100vh;

    img {
        max-width: 75vw;
    }

    h1 {
        color: #863DFF;
    }

    p {
        color: #5C5B5C;
    }

    &__texts {
        padding: 0 50px 50px 50px;
    }
}

.loader {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.load {
    display: inline-block;
    width: 3em;
    height: 3em;
    border: 0.25em solid rgba(0, 0, 0, 0.1);
    border-top-color: #03a7e5;
    border-radius: 50%;
    animation: spin 1s infinite linear;
    margin: 2em;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.termsAndConditionAction {
    font-size: 1rem;
    margin-left: 10px;
    margin-bottom: 15px;
    display: inline-block;
}

.modal-dialog {
    &__background {
        background-color: hsla(0, 0, 50, 0.8);
        z-index: 1000;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        min-width: 300px;
    }

    &__container {
        background-color: white;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 1.5em;
        margin: 5vw;
        min-width: 80vw;
        width: fit-content;
        height: fit-content;
        max-height: 70vh;
        overflow-y: auto;

        @media (min-width: 650px) {
            margin: 10vw;
            min-width: 600px;
            max-width: 35vw;

        }
    }

    &__title {
        color: #324053;
        font-size: 1.2rem;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        margin-top: 0;
    }

    &__info {
        color: #8B94A0;
        font-size: 1rem;
        line-height: 1.1em;
        text-align: left;
    }

    &__main-container {
        text-align: center;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1.2em;
    }

    &__buttons-container {
        align-self: flex-end;
        flex-wrap: nowrap;
        display: flex;
    }

    &__button {
        background-color: transparent;
        border-width: 1px;
        border-style: solid;
        margin-left: 1em;
        border-radius: 3px;
        padding: 0.8em 3em;
    }

    &__subtle-button {
        border-color: transparent;
        background-color: #fff;
        color: #ababab !important;
        transition: 0.3s;
        padding: 0.5em 1em;

        &:hover {
            color: #ababab !important;
            border: 1px solid #ababab;
        }

        &:active {
            color: #ababab;
            border: 1px solid #ababab;
        }
    }

    &__hidden-button {
        display: none !important;
    }

    &__cancel-button {
        border-color: #060a27;
        background-color: #F1F1F1;
        color: #060a27 !important;
        transition: 0.3s;
        padding: 0.5em 1em;

        &:hover {
            color: #fff !important;
            background-color: #CE5C5C;
        }

        &:active {
            color: #fff;
            background-color: #CE5C5C;
        }
    }

    &__confirm-button {
        border-color: transparent;
        background-color: $color-primary;
        color: #fff;
        transition: 0.3s;

        &:hover {
            color: #fff;
            background-color: #2B85C5;
        }

        &:active {
            color: #fff;
            background-color: #2B85C5;
        }
    }

    &--close {
        animation: fadeOut .6s linear forwards;

        .modal-dialog__container {
            animation: scaleDown .6s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
    }

    &--open {
        animation: fadeIn .5s cubic-bezier(0, 1, 1, 1) forwards;

        .modal-dialog__container {
            animation: scaleUp .4s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
    }
}

@keyframes fadeOut {
    0% {
        background: hsla(0, 0, 50, 0.8);
    }

    100% {
        background: rgba(0, 0, 0, .0);
    }
}

@keyframes scaleDown {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 1;
    }

    100% {
        transform: scale(.8) translateY(1000px);
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        background: rgba(0, 0, 0, .1);
    }

    100% {
        background: hsla(0, 0, 50, 0.8);
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(.6) translateY(1000px);
        opacity: 0;
    }

    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
    }
}