.loaderContentDiv {
    position: relative;
    width: 100%;
}

.slider {
    width: 115px;
    height: 115px;
    position: relative;
    visibility: visible;
    margin: 55px auto 50px;
    @media (max-width: 992px){
        margin: 75px auto 50px;
    }
}

.slide {
    width: 115px;
    height: 115px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    animation: fade 20s infinite;

}

.slider.sliderText {
    width: 385px !important;
    height: 70px !important;
    margin: 20px auto 10px !important;

    @media (max-width: 600px) {
        width: 260px !important;

    }
    @media (max-width: 375px) {
        width: 230px !important;

    }
}

.slide.text {
    width: 385px !important;
    height: 50px !important;
    font-size: 14px;
    color: #8f8f8f;

    @media (max-width: 600px) {
        width: 260px !important;

    }
    @media (max-width: 375px) {
        width: 230px !important;

    }
}

.slide:nth-child(1) {
    background: url('../../img/wait2.png') left top no-repeat;
    background-size: 100%;
}

.slide:nth-child(2) {
    background: url('../../img/wait1.png') left top no-repeat;
    background-size: 100%;

    animation-delay: 5s;
}

.slide:nth-child(3) {
    background: url('../../img/wait3.png') left top no-repeat;
    background-size: 100%;

    animation-delay: 10s;
}

.slide:nth-child(4) {
    background: url('../../img/wait4.png') left top no-repeat;
    background-size: 100%;
    animation-delay: 15s;
}

.slide.text:nth-child(1) {
    background: none !important;
}

.slide.text:nth-child(2) {
    background: none !important;

    animation-delay: 5s;
}

.slide.text:nth-child(3) {
    background: none !important;

    animation-delay: 10s;
}

.slide.text:nth-child(4) {
    background: none !important;
    animation-delay: 15s;
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    25% {
        opacity: 1;
    }

    30% {
        opacity: 0;
    }
}

.loaderVideocall {
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    border: 3px solid #2e9fee;
    border-top-color: #85bae0;
    box-sizing: border-box;
    background: transparent;
    -webkit-animation: loading 1s linear infinite;
    animation: loading 2s linear infinite;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: -20px;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }

    0% {
        transform: rotate(360deg);
    }
}