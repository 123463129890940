.error-info--item {
    &__header {
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1e9be5;

        padding: 0.5em 0;
    }

    &__description {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #3d4654;
        padding: 0.5em 0;
    }

    &__button {
        margin-top: 1em;
    }
}